import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'Tutorial',
    templateUrl: './tutorial.component.html'
})
export class TutorialComponent implements OnInit{
    public TUTORIAL_IMAGE_DYNAMIC: any = (id: number) => environment.TUTORIAL_IMAGE_DYNAMIC(id);
    public SCHOOL_NAME: string = environment.SCHOOL_NAME;
    
    public tutorials: any[] = [
        {
            id: 1,
            school: "CEDOC",
            title: 'Ingreso',
            link: 'https://drive.google.com/file/d/1_57kfl7u1P0M8dmBbQEg4tqGeEwXcPFT/view',
        },
        {
            id: 2,
            school: "CEDOC",
            title: 'Registro | Evaluacion institucional',
            link: 'https://drive.google.com/file/d/1n_rhuM9YmLkmKhpUcRb2BKlpD49qYjDq/view',
        },
        {
            id: 3,
            school: "CEDOC",
            title: 'Instrumentos',
            link: 'https://drive.google.com/file/d/10BMkZuoWqWSbTDno4vpc5zHNm3EQN7dQ/view',
        },
        {
            id: 4,
            school: "CEDOC",
            title: 'Classroom | Google workspace',
            link: 'https://drive.google.com/file/d/1XDDPJei11XRVF9Z3UM-bR0Uj-j2a2MSv/view',
        },
        {
            id: 5,
            school: "CEDOC",
            title: 'Forms | Google workspace',
            link: 'https://drive.google.com/file/d/1qpfymDT0mNVR6dsMoMYev_t5Qi1NpY7-/view',
        },
        {
            id: 6,
            school: "CEDOC",
            title: 'Hoja de vida',
            link: 'https://drive.google.com/file/d/1h0ATOUysrv0vVI4SLCWBbx9aDBgHgFqG/view',
        },
        {
            id: 7,
            school: "CEDOC",
            title: 'Inscripción | Ofertas Academicas',
            link: 'https://drive.google.com/file/d/1vw70_V4G6-APCG1TgSGTdlFaCgSfp17d/view',
        },
        {
            id: 8,
            school: "CEDOC",
            title: 'Foto de perfil | Google workspace',
            link: 'https://drive.google.com/file/d/1LDIbeuVuNTcUqioqKzFz9byqCTOFCk8x/view',
        },
        {
            id: 9,
            school: "CEDOC",
            title: 'Administracion | Administracion',
            link: 'https://drive.google.com/file/d/1wso78sKKa-wEp0c_ECIUiBjrK5jlC3Yu/view',
        },
        {
            id: 10,
            school: "CEDOC",
            title: 'Inscripción | Area academica',
            link: 'https://drive.google.com/file/d/1vy_phgKi6LRgDGIEOgDbx4y60vXRExpF/view',
        },
        {
            id: 11,
            school: "CEDOC",
            title: 'Malla curricular | Creacion y Administracion',
            link: 'https://drive.google.com/file/d/1tB5ufH1OvT4GsrMRaZlKwpbC5lIRWArG/view',
        },
        {
            id: 12,
            school: "CEDOC",
            title: 'Contraseña | Cambiar y Restaurar',
            link: 'https://drive.google.com/file/d/1rQY2Ak7_sciAM1--VIQ2kYN-0OIj3O5A/view',
        },
        {
            id: 13,
            school: "CEDOC",
            title: 'Grupos | Creacion de grupos',
            link: 'https://drive.google.com/file/d/1p2tjSnQ4OOemA-TSf0b4WakZQ5RrohEk/view',
        },
        {
            id: 14,
            school: "CEDOC",
            title: 'Grupos | Edicion de grupos',
            link: 'https://drive.google.com/file/d/1gE_YeVevgFk_gbypdThW-u6TVFJbdh-r/view',
        },
        {
            id: 15,
            school: "CEDOC",
            title: 'Horarios | Crear horarios',
            link: 'https://drive.google.com/file/d/1qBzKn-sRx-CJKWmsuTy52eI0NYTBpgO3/view',
        },
        {
            id: 16,
            school: "CEDOC",
            title: 'Horarios | OAP automatica',
            link: 'https://drive.google.com/file/d/1MdiBc-hsoqBCYNVPgU8kDksXHqi-w2Mx/view',
        },
        {
            id: 17,
            school: "CEDOC",
            title: 'Administrar | Fechas Academicas',
            link: 'https://drive.google.com/file/d/1MdiBc-hsoqBCYNVPgU8kDksXHqi-w2Mx/view',
        },
        {
            id: 18,
            school: "CEDOC",
            title: 'Cronograma academico',
            link: 'https://drive.google.com/file/d/1WNUPMq8KEL3QnXSkrIYI50qIatKaD-0q/view',
        },
        {
            id: 19,
            school: "CEDOC",
            title: 'Oferta Academica | Crear',
            link: 'https://drive.google.com/file/d/1bWa8490pGW60SWLm6cDm3Y5KBwBscVyL/view',
        },
        {
            id: 20,
            school: "CEDOC",
            title: 'Cronograma academico | Oferta anual',
            link: 'https://drive.google.com/file/d/17DRla1AizBX0w4XOZo3qCTlevVpADKiG/view',
        },
        {
            id: 21,
            school: "CEDOC",
            title: 'Cronograma academico | DIEDU',
            link: 'https://drive.google.com/file/d/17DRla1AizBX0w4XOZo3qCTlevVpADKiG/view',
        },
        {
            id: 22,
            school: "CEDOC",
            title: 'Administración de Usuarios | ADMIN',
            link: 'https://drive.google.com/file/d/1zX6qDGa8g8utSSY9bAdhmC8ETFaaitbV/view',
        },
        {
            id: 23,
            school: "CEDOC",
            title: 'Gestion de Roles | ADMIN',
            link: 'https://drive.google.com/file/d/17DRla1AizBX0w4XOZo3qCTlevVpADKiG/view',
        },
        {
            id: 24,
            school: "CEDOC",
            title: 'Bienestar | Crear Noticia / Artículos / Correo',
            link: 'https://drive.google.com/file/d/1w6WDmRbUfnMw9P7iA7VVfc1C1veQYSmH/view',
        },
        {
            id: 25,
            school: "CEDOC",
            title: 'Bienestar | Crear evento',
            link: 'https://drive.google.com/file/d/1_QVQPle1hZ-_YlsFBEY9KrYd0r7khWGt/view',
        },
        {
            id: 26,
            school: "CEDOC",
            title: 'Bienestar | Inscribirse a Evento',
            link: 'https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view',
        },
        {
            id: 27,
            school: "CEDOC",
            title: 'Bienestar',
            link: 'https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view',
        },
        {
            id: 28,
            school: "CEDOC",
            title: 'Educación | Superior',
            link: 'https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view',
        },
        {
            id: 29,
            school: "CEDOC",
            title: 'Carga academica | Inscipción estudiantes',
            link: 'https://drive.google.com/file/d/16b6M66z7SgrmPouaiYAB9qVHHHX4tAp5/view',
        },
        {
            id: 30,
            school: "CEDOC",
            title: 'Instrumentos | Evaluación 2.0',
            link: 'https://drive.google.com/file/d/1dbBJ315DfBlRBbc4noZXgQENOQzreHOj/view',
        },
        {
            id: 32,
            school: "CEDOC",
            title: 'Homologación',
            link: 'https://drive.google.com/file/d/11TturZYv1MeBQIhqAWHZwktiumbEcrKS/view?usp=sharing',
        },
        {
            id: 33,
            school: "CEDOC",
            title: 'Cargar Recibos',
            link: 'https://drive.google.com/file/d/1SGX9pQy257jIJJbmCrIB8L2JY1xkfVha/view?usp=sharing',
        },
        {
            id: 34,
            school: "CEDOC",
            title: 'Plan de mejora',
            link: 'https://drive.google.com/file/d/1Lp7hsZFudM-dtnGgrwZOkmLOQMLN1vvr/view?usp=sharing',
        },
        {
            id: 35,
            school: "CEDOC",
            title: 'Convocatorias internas',
            link: 'https://drive.google.com/file/d/1E6kqijkRJ1wBxuOY7O88qa_J09xBE1_F/view?usp=share_link',
        },

        /* === CELIC */

        {
            id: 1,
            school: "CELIC",
            title: 'Registro Acudiente',
            link: 'https://drive.google.com/file/d/1LaWpvZyRRXXc_piCZb5V7KULNHyPQwpX/view?usp=drive_link',
        },
        {
            id: 2,
            school: "CELIC",
            title: 'Ingreso de Acudiente y registro de hijos',
            link: 'https://drive.google.com/file/d/1IZsTZXwyGl8bd_300TQUJWt2xkWOAZBt/view?usp=drive_link',
        },
        {
            id: 3,
            school: "CELIC",
            title: 'Solicitud de inscripción',
            link: 'https://drive.google.com/file/d/1PzLK4NjjHCWUxhp-bTkSSQFAV-UYc9qD/view?usp=drive_link',
        },
        {
            id: 4,
            school: "CELIC",
            title: 'Registro docente',
            link: 'https://drive.google.com/file/d/1PocmI9sKIGLz3VrxSKbqWy2AaSjm1QJ6/view?usp=drive_link',
        },
    ]

    ngOnInit(): void {
        this.tutorials = this.tutorials.filter( e => e.school === this.SCHOOL_NAME );
    }
}