
//Here we will store all the variables required by the components.
//If we want, we can initialize them with default values

interface SchoolToLoadOptions {
  school?: string;

  //#region SCHOOL LOADER
  loader?:string;
  bg?:string;
  tank?:string;
  cloud?:string;
  henoBall?: string;
  bgDesert?: string;
  //#endregion

  mainSchoolLogoImg?: string;
  mainSchoolLogoSVG?: number;

  cemilIcon?: string,
  ejcIcon?: string,

  politicsSchoolLogo?: string;
  politicsLogoBottom?: string;

  defaultAvatar?: string;
}


//Default storages
const storage = {
  cedoc: 'https://storage.googleapis.com/assets_cedoc',
  celic: 'https://storage.googleapis.com/celic_assets',
}


/**
 * Default school variables
 * @param school 
 * @returns 
 */
export const schoolToLoad = (
  { 
    school = 'cedoc',

    //#region SCHOOL LOADER
    loader = 'loader',
    bg = 'sign',
    tank = 'car',
    cloud = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/43033/poof.svg',
    henoBall = 'heno-ball-404',
    bgDesert = 'bg-desert-404',
    //#endregion

    mainSchoolLogoImg = 'logo',
    mainSchoolLogoSVG = 0,

    cemilIcon = 'Icono_CEMIL',
    ejcIcon = 'Icono_EJC',

    politicsSchoolLogo = 'Logo_cedoc_rojo',
    politicsLogoBottom = 'Logo.politicas.abajo',

    defaultAvatar = 'default',
   }: SchoolToLoadOptions = {}) => {
  return {
    SCHOOL_LOADER:{
      LOADER: `${storage[school]}/modules/pages/common/img/${loader}.png`,
      BG: `${storage[school]}/modules/pages/common/img/${bg}.png`,
      TANK: `${storage[school]}/modules/pages/common/img/${tank}.png`,
      CLOUD: cloud,
      HENO_BALL: `${storage[school]}/modules/pages/common/img/${henoBall}.png`,
      DESERT_BG: `${storage[school]}/modules/pages/common/img/${bgDesert}.png`,
    },

    SCHOOL_LOGO_DYNAMIC_SVG: (id: number = 0) => `${storage[school]}/schools/logo/${id}.svg`,
    SCHOOL_PHOTO_DYNAMIC_NEWS: (id: string = '') => `${storage[school]}/modules/pages/common/img/photos/esc${id}.jpg`,
    SCHOOL_PHOTO_DYNAMIC: (id: number = 0) => `${storage[school]}/schools/photo/${id}.jpg`,
    FORCE_LOGO_DYNAMIC: (id: number = 0) => `${storage[school]}/modules/pages/common/img/photos/Logos_Fuerzas-${id}.png`,

    DYNAMIC_SCHOOL_PHOTO: (id: number = 0) => `${storage[school]}/modules/pages/common/img/photos/esc${id}.jpg`,

    TUTORIAL_IMAGE_DYNAMIC: (id: number = 1) => `${storage[school]}/schools/tutorials/${id}.png`,
    TUTORIAL_LAYOUT_DYNAMIC: (id: string = '') => `${storage[school]}/modules/layouts/${id}.jpg`,

    MAIN_SCHOOL_LOGO_SVG: `${storage[school]}/schools/logo/${mainSchoolLogoSVG}.svg`,
    MAIN_SCHOOL_LOGO_IMG: `${storage[school]}/modules/pages/common/img/${mainSchoolLogoImg}.png`,

    CEMIL_ICON: `${storage[school]}/modules/pages/common/img/${cemilIcon}.png`,
    EJC_ICON: `${storage[school]}/modules/pages/common/img/${ejcIcon}.png`,

    LINK_LOGOS: {
      LOGO_01: `${storage[school]}/modules/pages/common/img/links/logos-01.png`,
      LOGO_02: `${storage[school]}/modules/pages/common/img/links/logos-02.png`,
      LOGO_03: `${storage[school]}/modules/pages/common/img/links/logos-03.png`,
      LOGO_04: `${storage[school]}/modules/pages/common/img/links/logos-04.png`,
      LOGO_05: `${storage[school]}/modules/pages/common/img/links/logos-05.png`,
      CERTIFICATION: `${storage[school]}/modules/pages/common/img/links/certificacion.png`,
    },

    POLITIC_LOGOS: {
      SCHOOL_LOGO: `${storage[school]}/modules/pages/common/img/${politicsSchoolLogo}.png`,
      MAIN_POLITIC_LOGOS: `${storage[school]}/modules/pages/common/img/Logo_politicas.png`,
      BOTTOM_LOGO: `${storage[school]}/modules/pages/common/img/${politicsLogoBottom}.png`,
    },

    DEFAULT_AVATAR: `${storage[school]}/modules/pages/common/img/avatars/${defaultAvatar}.png`,

    CV_IMAGES: {
      E_001: `${storage[school]}/modules/ecommerce/common/img/001.png`,
      E_002: `${storage[school]}/modules/ecommerce/common/img/002.png`,
      E_003: `${storage[school]}/modules/ecommerce/common/img/003.png`,
    },

    SCHOOL_NAME: school.toUpperCase()
  }
}



